import React, { useState } from "react";

import { Nav, Card, Button, Row, Col, Container } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const items = [
  {
    title:
      "Unternehmensexkursion zum Circular Digital Economy Lab und FabLab der Hochschule Ruhr West",
    category: "Blog",
    description:
      "Am Montag, den 13. Mai, hatte das Mittelstand-Digital Zentrum WertNetzWerke die Gelegenheit, eine inspirierende Unternehmensexkursion zu zwei innovativen Einrichtungen zu veranstalten: Zum Circular Digital Economy Lab und dem FabLab der Hochschule Ruhr West in Bottrop.",
    contact_person: "Janna Prager",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/blog/unternehmensexkursion-zum-circular-digital-economy-lab-und-fablab/",
  },
  {
    title:
      "Circularity-Scouts 2024: Auszubildende als Wegbereiter für eine nachhaltige Zukunft",
    category: "Blog",
    description:
      "Am 28. Februar war das Mittelstand-Digital Zentrum WertNetzWerke Teil des Circularity-Scouts-Programm 2024 der IHK Köln. Mit dem Programm bietet die IHK Köln Auszubildenden die Möglichkeit, eine Zusatzqualifikation im Bereich der Circular Economy zu erwerben.",
    contact_person: "Janna Prager",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/blog/circularity-scouts-2024-auszubildende-als-wegbereiter-fuer-eine-nachhaltige-zukunft/",
  },
  {
    title: "Lerntransfer-Nugget: KI und Nachhaltigkeit",
    category: "Handreichung",
    description:
      "Welche Chancen und Risiken Künstliche Intelligenz für die Nachhaltigkeit bietet, erfahren Sie in diesem Lerntransfer-Nugget.",
    contact_person: "Mike Tabel",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/fileadmin/user_upload/Materialien/Wissensbox/mittelstand-digital-zentrum-wertnetzwerke-transfer-nugget-ki-nachhaltigkeit.pdf",
  },
  {
    title:
      "Wie KI den Weg zu nachhaltigem Wirtschaften ebnet: Ein Blick auf kleine und mittlere Unternehmen",
    category: "Blog",
    description:
      "In einer Zeit, in der Nachhaltigkeit immer wichtiger wird, suchen Unternehmen nach innovativen Lösungen, um ihre Geschäftspraktiken umweltfreundlicher zu gestalten. Eine vielversprechende Technologie ist KI. In diesem Artikel werfen wir einen Blick darauf, wie KI dazu beitragen kann, nachhaltiger zu wirtschaften.",
    contact_person: "Mike Tabel",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/blog/wie-kuenstliche-intelligenz-den-weg-zu-nachhaltigem-wirtschaften-ebnet/",
  },
  {
    title:
      "Mehrweg-Behälter am Empfang: Ein Schritt zu mehr Nachhaltigkeit im Büroalltag – Essen To-Go ohne Einweg",
    category: "Blog",
    description:
      "Die Notwendigkeit, unseren Umgang mit Verpackungsmaterialien zu überdenken, ist evident. Dies gilt insbesondere seit der Einführung der Mehrwegpflicht für Gastronomiebetriebe. Trotzdem bleiben Mehrwegverpackungen in der Praxis ungenutzt, da ihre Rückgabe oft als umständlich empfunden wird. Das Digitalisierungsprojekt zielt darauf ab, diese Hürde zu überwinden, indem Mehrwegbehälter direkt am Arbeitsplatz zurückgegeben werden.",
    contact_person: "Janna Prager",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/blog/mehrweg-behaelter-am-empfang-nachhaltigkeit-im-bueroalltag/",
  },
  {
    title: "KI Anwendungsbeispiele im Überblick",
    category: "Handreichung",
    description:
      "In dieser Übersicht werden Anwendungsbeispiele für KI in verschiedenen Bereichen der Wertschöpfungskette vorgestellt",
    contact_person: "Mike Tabel",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/fileadmin/user_upload/Materialien/Wissensbox/mittelstand-digital-zentrum-wertnetzwerke-material-KI-anwendungsbeispiele.pdf",
  },
  {
    title:
      "Potenzial-Checkliste zur Bewertung erfolgreicher Unternehmenskooperationen",
    category: "Handreichung",
    description:
      "Die Entscheidung, mit anderen Organisationen zusammenzuarbeiten, stellt eine wichtige strategische Überlegung dar. Um sicherzustellen, dass eine Zusammenarbeit den langfristigen Zielen und Werten Ihres Unternehmens entspricht, ist eine umfassende Prüfung erforderlich. Unsere Checkliste dient der Evaluierung einer Zusammenarbeit im Vorfeld und ist ein nützliches Instrument, mit dem Sie sicherstellen können, dass relevante Aspekte einer potenziellen Kooperation berücksichtigt werden.",
    contact_person: "Janna Prager",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/fileadmin/user_upload/Materialien/Wissensbox/mittelstand-digital-zentrum-wertnetzwerke-foerderung-kooperation-digitalisierungsprojekten.pdf",
  },
  {
    title:
      "Wissensnugget: Nachhaltigkeit und Kreislaufwirtschaft, EU-Begriffe erklärt",
    category: "Handreichung",
    description:
      "Aktuelle Regularien der EU sollen Verantwortliche in mittelständischen Unternehmen auf die Themen Nachhaltigkeit und Kreislaufwirtschaft aufmerksam machen. Unser Wissensnugget bietet einen Überblick zu Relevanz, Orientierung und Zielsetzung der Regularien und hilft Mitarbeitenden in KMU, ein Bewusstsein der eigenen Rolle und ihrer Verantwortung zu erhalten.",
    contact_person: "Tim Bartram",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/fileadmin/user_upload/Materialien/Wissensbox/mittelstand-digital-zentrum-wertnetzwerke-wissensnugget-nachhaltigkeit-kreislaufwirtschaft-orientierung-reg.pdf",
  },
  {
    title:
      "Wissensnugget: Gesetzgebung zur Nachhaltigkeit und Kreislaufwirtschaft",
    category: "Handreichung",
    description:
      "Unser Wissensnugget dient als Anleitung auf dem Weg zu Nachhaltigkeit und Kreislaufwirtschaft und zielt auf den eigenen Nutzen im Unternehmen und neue Geschäftsmodelle ab. Hierbei gilt es, globale Ziele im Auge zu behalten und gleichzeitig lokal gute Ergebnisse für sich zu erzielen, wie beispielsweise Digitalisierung, Berichtswesen, Bürokratieabbau, Kundenanfragen und mehr.",
    contact_person: "Tim Bartram",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/fileadmin/user_upload/Materialien/Wissensbox/mittelstand-digital-zentrum-wertnetzwerke-wissensnugget-nachhaltigkeit-kreislaufwirtschaft-europaeische-reg.pdf",
  },
  {
    title:
      "Kooperative Geschäftsmodelle für die Kreislaufwirtschaft - Erfolgsfaktoren für eine zirkuläre, erfolgreiche und kooperative Zusammenarbeit von kleinen und mittleren Unternehmen",
    category: "Leitfaden",
    description:
      "Unser Leitfaden dient als Erläuterung von 13 Erfolgsfaktoren für kooperative Geschäftsmodelle für die Kreislaufwirtschaft.",
    contact_person: "David Ziegler/Julian Heinrich",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/fileadmin/user_upload/Materialien/Sonstiges/mittelstand-digital-zentrum-werknetzwerke-leitfaden-kooperative-geschaeftsmodelle-kreislaufwirtschaft.pdf",
  },
  {
    title:
      "Mehrweg-Behälter bequem in unseren Alltag integrieren: Wie erreichen wir eine Lösung? Convenient statt kompliziert – Mehrweg zurück geben am Empfang im Büro",
    category: "Projektsteckbrief",
    description:
      "Die Notwendigkeit, unseren Umgang mit Verpackungsmaterialien zu überdenken, ist evident. Dies gilt insbesondere seit der Einführung der Mehrwegpflicht für Gastronomiebetriebe. Trotzdem bleiben Mehrwegverpackungen in der Praxis ungenutzt, da ihre Rückgabe oft als umständlich empfunden wird. Das Digitalisierungsprojekt namens Mehrweg am Empfang zielt darauf ab, diese Hürde zu ",
    contact_person: "Janna Prager",
    link: "https://www.mittelstand-digital-wertnetzwerke.de/digitalisierungsprojekte/projektsteckbriefe/mehrweg-behaelter-wie-erreichen-wir-eine-loesung-convenient-statt-kompliziert-mehrweg-zurueck-geben-im-buero/",
  },
  {
    title: "SustainableSteps",
    category: "Demonstrator",
    description:
      "Unser Tool unterstützt Sie dabei, den Ist-Zustand Ihres Unternehmens in Bezug auf Nachhaltigkeitsdaten zu ermitteln und zeigt Ihnen, wie weitere Schritte hin zu mehr Nachhaltigkeit durch Digitalisierung aussehen könnten. ",
    contact_person: "Janna Prager",
    link: "",
  },
];
const MaterialPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const categories = [
    "Blog",
    "Handreichung",
    "Projektsteckbrief",
    "Leitfaden",
    "Demonstrator",
  ];

  const filteredItems =
    selectedCategory === "All"
      ? items
      : items.filter((item) => item.category === selectedCategory);

  const handleCategoryClick = (category) => {
    // If the same category is clicked, clear the filter by setting 'All'
    if (selectedCategory === category) {
      setSelectedCategory("All");
    } else {
      setSelectedCategory(category);
    }
  };

  return (
    <>
      <Container fluid>
        <Row
          className="justify-content-center mb-4"
          style={{ backgroundColor: "#ededed", padding: "10px 0" }}
        >
          <Nav
            className="d-flex flex-wrap justify-content-center"
            style={{ width: "100%" }}
          >
            {categories.map((category) => (
              <Button
                key={category}
                variant="link"
                className={`btn icon-button d-flex flex-column align-items-center mx-2 ${
                  selectedCategory === category ? "active" : ""
                }`}
                style={{
                  color:
                    selectedCategory === category ? "rgb(184, 9, 0)" : "black",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                onClick={() => handleCategoryClick(category)}
              >
                <i
                  className={`bi bi-${
                    category === "Blog"
                      ? "journal-text"
                      : category === "Handreichung"
                      ? "filetype-pdf"
                      : category === "Projektsteckbrief"
                      ? "briefcase"
                      : category === "Leitfaden"
                      ? "file-earmark-text"
                      : "laptop"
                  }`}
                  style={{ fontSize: "40px", marginBottom: "5px" }}
                ></i>
                <span>{category}</span>
              </Button>
            ))}
          </Nav>
        </Row>

        <Row className="px-3">
          {filteredItems.map((item, index) => (
            <Col key={index} className="mb-4" xs={12} sm={6} md={4} lg={3}>
              <Card
                style={{
                  borderColor: "transparent",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  color: "#333",
                  cursor: "pointer",
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
                onClick={() => window.open(item.link, "_blank")}
              >
                <Card.Body className="d-flex flex-column justify-content-between p-3">
                  <div>
                    <Card.Title
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        borderBottom: `2px solid rgb(184, 9, 0)`,
                        paddingBottom: "8px",
                        marginBottom: "10px",
                      }}
                    >
                      {item.title}
                    </Card.Title>
                  </div>
                  <Card.Text
                    style={{ fontSize: "0.875rem", fontWeight: "normal" }}
                  >
                    {item.description}
                  </Card.Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "0.875rem",
                      marginTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <Card.Text
                      style={{
                        backgroundColor: "rgba(184, 9, 0, 0.1)",
                        borderRadius: "4px",
                        padding: "2px 6px",
                        color: "rgb(184, 9, 0)",
                        margin: 0,
                      }}
                    >
                      {item.category}
                    </Card.Text>
                    <Card.Text style={{ margin: 0 }}>
                      <small>Ansprechperson: {item.contact_person}</small>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <style jsx>{`
        .icon-button {
          font-weight: normal;
        }
        .icon-button.active {
          font-weight: bold;
          color: rgb(184, 9, 0) !important;
        }
        .icon-button:hover {
          color: rgb(184, 9, 0) !important;
        }
        .icon-button:hover i {
          color: rgb(184, 9, 0) !important;
        }
        .icon-button:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      `}</style>
    </>
  );
};

export default MaterialPage;
